<!--
 * @Descripttion: 回复详情
 * @Author: zyr
 * @Date: 2021-05-29 22:54:17
 * @LastEditors: zyr
 * @LastEditTime: 2021-09-18 00:33:59
-->
<template>
  <div :class="{'detail-wrapper': true, 'home-wrapper': !info.list.length}">
    <div :class="{'detail-con': true, 'home-content': !info.list.length}">
      <div>
        <!-- 类型 -->
        <div class="detail-type">类型: <span class="type-txt">{{info.type | getType(typeList)}}</span></div>
        <!-- 问题 -->
        <div class="common-reply reply-que">
          <h6 class="reply-tlt">{{info.title}}</h6>
          <div class="reply-desc">{{info.content}}</div>
          <p class="reply-date">{{info.date}}</p>
        </div>
        <!-- 回答 -->
        <div v-if="info.list.length" class="ans-list">
          <div class="ans-wrapper" v-for="v in info.list" :key="v.id">
            <div class="common-reply reply-ans">
              <!-- 用户信息 -->
              <div class="user-info hack-border-bottom user-flex">
                <div class="user-intro">
                  <h5 class="user-name align-right">{{v.userinfo.name}}</h5>
                  <p class="user-title align-right">{{v.userinfo.duties}}</p>
                </div>
              </div>
              <!-- 回复内容 -->
              <div class="user-mail reply-mail">{{v.content}}<a v-if="v.fileurl" :href="v.fileurl" class="mail-a">{{v.filename}}</a></div>
              <!-- 时间 -->
              <div class="user-mail mail-date">{{v.date}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 无回复 -->
      <van-button block color="#fff" v-if="!info.list.length">暂无回复</van-button>
    </div>
    <!-- <div class="home-footer">
      <p class="text-align">XX提供技术支持</p>
      <a href="tel:0511-3455899" class="footer-phone text-align">0511-3455899</a>
    </div> -->
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { getType, replyList } = API
export default {
  name: 'Detail',
  data () {
    return {
      info: {
        type: 0,
        title: '',
        content: '',
        date: '',
        list: []
      },
      typeList: [],
      isBottom: false
    }
  },
  methods: {
    getDetail (id) {
      this.$axios({
        url: `${replyList}${id}`,
        method: 'get',
        headers: { tkn: this.$store.state.token }
      }).then(({ code, msg, model }) => {
        if (code === 0) {
          this.info = model
        }
      })
    },
    getActions () {
      this.$axios({
        url: getType,
        method: 'get',
        headers: { tkn: this.$store.state.token }
      }).then(({ code, msg, model }) => {
        if (code === 0) this.typeList = model
      })
    },
    getBottm () {
      if (!this.info.list.length) return
      const obj = document.querySelector('.ans-list .ans-wrapper:last-child').getBoundingClientRect()
      const height = document.getElementById('app').clientHeight
      this.isBottom = obj.bottom < height
    }
  },
  filters: {
    getType (type, typeList) {
      const item = typeList.find(v => v.id === type)
      return item ? item.name : ''
    }
  },
  beforeRouteEnter (to, from, next) {
    const { query: { id } } = to
    next(vm => {
      vm.getActions()
      vm.getDetail(id)
      // vm.isBottom = true
    })
  }
}
</script>
<style lang='less'>
@import '../../assets/less/flex.less';
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
}
.van-button__text{
  color: #8993A8;
  font-size: .px2rem(32px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
.detail-wrapper{
  background: #F6F7FB;
}
.detail-con{
  padding: .px2rem(32px)[@rem] 0;
  margin: 0 .px2rem(32px)[@rem];
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.type-txt{
  padding-left: .px2rem(16px)[@rem]
}
.detail-type{
  margin: 0 auto .px2rem(30px)[@rem];
  padding: 0 .px2rem(30px)[@rem];
  line-height: .px2rem(54px)[@rem];
  width: fit-content;
  background: #fff;
  border-radius: .px2rem(27px)[@rem];
  font-size: .px2rem(28px)[@rem];
  color: #8993A8;
}
.common-reply{
  padding: .px2rem(32px)[@rem];
  box-sizing: border-box;
  border-radius: .px2rem(40px)[@rem];
  width: .px2rem(606px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.reply-que{
  background: #5460FE;
  border-top-left-radius: 0;
  color: #fff;
}
.reply-tlt{
  font-size: .px2rem(32px)[@rem];
  margin-bottom: .px2rem(16px)[@rem];
}
.reply-desc{
  font-size: .px2rem(28px)[@rem];
  margin-bottom: .px2rem(32px)[@rem];
}
.reply-date{
  font-size: .px2rem(28px)[@rem];
  text-align: right;
}
.ans-wrapper{
  display: flex;
  justify-content: flex-end;
}
.reply-ans{
  border-top-right-radius: 0;
  background: #fff;
  margin-bottom: .px2rem(26px)[@rem];
}
.ans-img{
  margin-left: .px2rem(56px)[@rem];
  margin-right: 0;
}
.align-right{
  text-align: right;
}
.reply-mail{
  margin-top: .px2rem(20px)[@rem];
}
.mail-date{
  margin-top: .px2rem(32px)[@rem];
}
.user-flex{
  justify-content: flex-end;
}
</style>
